<template>
  <div class="drop_down_filter_box">
    <div class="label_text_styles" v-if="labelText" :style="labelTextStyles">{{ labelText }}</div>
    <div style="margin:0 5px 0 20px;">
      <Select v-model="selectValue" transfer @on-change="changeInputValue" :style="selectStyle">
        <Option v-for="item in dropDownInputList" :key="item.value" :value="item.value">{{ item.title }}</Option>
      </Select>
    </div>
    <Input
      v-if="optionType === 'input'"
      :type="inputType"
      :clearable="inputType === 'text'"
      :placeholder="placeholder"
      v-model.trim="inputValue"
      :style="inputStyle">
    </Input>
    <Select v-if="optionType === 'select'" v-model="inputValue" transfer filterable :placeholder="placeholder" :style="inputStyle">
      <Option v-for="item in dropDownList" :key="item.value" :value="item.value">{{ item.name }}</Option>
    </Select>
  </div>
</template>

<script>
export default {
  name: "dropDownFilter",
  props: {
    dropDownInputList: { // 下拉选项筛选条件的基础数据
      type: Array,
      default: () => []
    },
    defaultSelectValue: { // 默认展示下拉选项值
      type: Number,
      default: 1
    },
    selectStyle: { // 下拉选项的样式
      type: Object,
      default: () => {
        return {
          width: '110px'
        };
      }
    },
    inputType: { // 下拉选项筛选输入框的类型
      type: String,
      default: 'text'
    },
    inputStyle: { // 下拉选项筛选输入框的样式
      type: Object,
      default: () => {
        return {
          flex: 1
        };
      }
    },
    optionType: { // 右边的操作框的类型  默认是input框
      type: String,
      default: 'input'
    },
    dropDownList: { // 右边的操作框类型为select框的基础数据
      type: Array,
      default: () => {
        return []
      }
    },
    labelText: { // 筛选文本
      type: String,
      default: ''
    },
    labelTextStyles: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  data() {
    return {
      selectValue: '',
      inputValue: '',
      placeholder: '',
      parameterKey: ''
    };
  },
  methods: {
    // 切换选项
    changeInputValue(value) {
      this.inputValue = '';
      this.$emit('changeInput', value);
    }
  },
  watch: {
    // 监听下拉选项的默认值
    defaultSelectValue: {
      handler(value) {
        let v = this;
        v.selectValue = value;
        v.inputValue = '';
        v.placeholder = v.dropDownInputList[value - 1].placeholder ? v.dropDownInputList[value - 1].placeholder : '';
        v.parameterKey = v.dropDownInputList[value - 1].parameterKey;
      },
      immediate: true,
      deep: true
    },
    // 监听下拉选项筛选条件的值
    inputValue: {
      handler(value) {
        let v = this;
        v.$emit('updateInputValue', {
          key: v.parameterKey,
          value: value
        });
      },
      immediate: true,
      deep: true
    }
  }
};
</script>

<style lang="less" scoped>
.drop_down_filter_box {
  display: flex;

  .label_text_styles {
    text-align: right;
    height: 32px;
    line-height: 32px;
  }

  /deep/ .ivu-select-selected-value {
    font-size: 12px !important;
  }
}
</style>